import pro1 from "../assets/movie rec system photo.png"

const ProjectCardData = [
    {
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system."
    },
    {
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system."
    },
    {
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system."
    },
    {
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system.",
        link:"https://github.com/Cuberswarm/Movie-Recommendation-System"
    },{
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system.",
        link:"https://github.com/Cuberswarm/OIBSIP"
    },{
        imgsrc: pro1,
        title: "Movie Recommendation System",
        text: "In this movie recommendation system we have implemented content-based recommendation system."
    }
   
   

]

export default ProjectCardData