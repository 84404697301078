import "./Workcard.css";
import React from 'react'
import {NavLink} from "react-router-dom";

const Workcard = (props) => {
  return (
    <div className="work-container">
        <div className="project-container">
            <div className="project-card">
                <img src={props.imgsrc} alt="egami" />
                <h2 className="project-title">{props.title}</h2>
                <div className="pro-details">
                    <p>{props.text}</p>
                    <div className="pro-btns">
                        <NavLink to={props.link}>
                            <button id="button1">view</button>
                        </NavLink>
                        {/* <NavLink to="url.com">
                            <button id="button1">source</button>
                        </NavLink> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Workcard