import React, { Component } from 'react'
import "./Heroimage2.css";

class Heroimage2 extends Component {
    render(){
        return (
            <div className="hero-img">
                <div className="heading">
                <h1>
                <span class="actual-text">&nbsp;{this.props.heading}&nbsp;</span>
                <span class="hover-text" aria-hidden="true">&nbsp;{this.props.heading}&nbsp;</span>
                </h1>
                    <p>{this.props.text}</p>
                </div>
            </div>
        ); 
    }
    
};

export default Heroimage2