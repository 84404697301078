import "./Form.css";
import React, { useState } from 'react'
import axios from 'axios'


function Contactus() {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post('https://portfolio-iojn.onrender.com/api/contact/add', { name, email, subject, message })
      .then(result => console.log("This is the result", result))
      .catch(err => console.log(err))
  }

  return <div className="form">
    <h1>Get in Touch.</h1>
    <form onSubmit={handleSubmit}>
      <label>Your Name</label>
      <input type="text" onChange={(e) => setName(e.target.value)}></input>
      <label>Email</label>
      <input type="text" onChange={(e) => setEmail(e.target.value)}></input>
      <label>Subject</label>
      <input type="text" onChange={(e) => setSubject(e.target.value)}></input>
      <label>Message</label>
      <textarea rows="7" placeholder="Start your conversation here" onChange={(e) => setMessage(e.target.value)} />
      <button className="a" type="submit">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        Submit
      </button>
    </form>
  </div>
}

export default Contactus