import React, { useState } from 'react'
import "./Toggle.css";

const Toggle = ({toggleClick,toggle}) => {
 

  return (
    <>
    <div>
        <label class="switch">
        <input type="checkbox"></input>
        <span class="slider"></span>
        </label>
    </div>

    </>
  )
}

export default Toggle