import "./Footer.css"

import React from 'react'
import {FaGithub, FaHome, FaInstagram, FaLinkedinIn, FaMailBulk, FaPhone} from "react-icons/fa"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="left">
                <div className="location">
                  <FaHome size={20} style={{color:
                  "#fff", marginRight: "2rem" }} />
                  <div>
                    <p>Pune.</p>
                    <p>India.</p>
                  </div>
                </div>
                <div className="phone">
                  <h4><FaPhone size={20} style={{color:
                  "#fff", marginRight: "2rem" }} />
                  +91 9682138803</h4>
                </div>
                <div className="email">
                  <h4><FaMailBulk size={20} style={{color:
                  "#fff", marginRight: "2rem" }} />
                  iampurnandu@gmail.com</h4>
                </div>
            </div>

            <div className="right">
              <h4>About me</h4>
              <p>I am a Undergrad Student,Who has interest in Web Development, Competitive Programmer and a Data Science and Machine Learning Enthusiast.</p>
              <div className="social">
                <Link to="https://www.instagram.com/purnandu_2002">

                <FaInstagram size={30} style={{ color:
                "#fff", marginRight:"1rem" }} />
                </Link >
                <Link to="https://linkedin.com/in/
purnandu-bhattacharya">
                <FaLinkedinIn size={30} style={{ color:
                "#fff", marginRight:"1rem" }} />
                </Link>
                <Link to="https://github.com/Cuberswarm">
                <FaGithub size={30} style={{ color:
                "#fff", marginRight:"1rem" }} />
                </Link>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Footer