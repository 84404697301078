import "./Navbar.css";
import {FaBars,FaTimes} from "react-icons/fa";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import Toggle from "./Toggle";


const Navbar = ({toggleClick,toggle}) => {
    const [click,setClick] = useState(false);
    const handleClick = () => setClick(!click);
    
    const [color, setColor] = useState(false);
    const changeColor = () =>{
        if(window.scrollY >= 200){
            setColor(true);
        }else{
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);
    return (
        <div className={color ? "header header-bg" :
        "header"}>
            <div className="nav-left">
                <Link to="/">
                    <h1>Portfolio.</h1>
                </Link>
                <Toggle toggleClick={toggleClick} toggle={toggle}/>
            </div>
            


            
            
            <u1 className={click ? "nav-menu active" :
            "nav-menu"}>
                <li>
                    <Link to = "/">Home.</Link>
                </li>
                <li>
                    <Link to = "/Skills">Skills.</Link>
                </li>
                <li>
                    <Link to = "/Project">Project.</Link>
                </li>
                <li>
                    <Link to = "/Contact">Contact.</Link>
                </li>
            </u1>
            <div className="hamburger" onClick={handleClick}>
                {click ? (
                    <FaTimes size={20} style={{ color: "#fff" }
                    } />
                ) : (
                    <FaBars size={20} style={{ color: "#fff" }
                } />
                )}
            </div>
        </div>
    );
};

export default Navbar
