import "./SkillsBar.css";
import React from 'react'

const Role = () => {
  return (
    <div className="main">
        <div className='container'>
        <h1 className='title-text'>Skills Progress Bar</h1>

        <div className='skill-box'>
            <span className='title'>HTML</span>
            <div className='skill-bar'>
                <span className='skill-per html'>
                    <span className='tooltip'>95%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>CSS</span>
            <div className='skill-bar'>
                <span className='skill-per css'>
                    <span className='tooltip'>85%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>JavaScript</span>
            <div className='skill-bar'>
                <span className='skill-per javascript'>
                    <span className='tooltip'>60%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>NodeJs</span>
            <div className='skill-bar'>
                <span className='skill-per nodejs'>
                    <span className='tooltip'>40%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>ReactJs</span>
            <div className='skill-bar'>
                <span className='skill-per reactjs'>
                    <span className='tooltip'>70%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>ExpressJs</span>
            <div className='skill-bar'>
                <span className='skill-per expressjs'>
                    <span className='tooltip'>75%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>Python</span>
            <div className='skill-bar'>
                <span className='skill-per python'>
                    <span className='tooltip'>90%</span>
                </span>
            </div>
        </div>
        <div className='skill-box'>
            <span className='title'>C++</span>
            <div className='skill-bar'>
                <span className='skill-per cpp'>
                    <span className='tooltip'>80%</span>
                </span>
            </div>
            <div className='skill-box'>
            <span className='title'>MySql</span>
            <div className='skill-bar'>
                <span className='skill-per mysql'>
                    <span className='tooltip'>90%</span>
                </span>
            </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Role