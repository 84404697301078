import React from 'react'
import Footer from './components/Footer'
import Heroimage2 from './components/Heroimage2'
import Navbar from './components/Navbar'
import Work from './components/Work'

const project = () => {
  return (
    <div>
        <Navbar />
        <Heroimage2 heading="PROJECTS." text="some of my recent works" />
        <Work />
        <Footer />
    </div>
  )
}

export default project