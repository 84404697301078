import React from 'react'
import "./Heroimage.css";
import IntroImg from "../assets/photo-1511447333015-45b65e60f6d5.jpeg"
import { Link } from 'react-router-dom';

const Heroimage = () => {
  return (
    <div className="hero">
        <div className="mask">
            <img className="into-img"
            src={IntroImg} alt="IntroImg" />
        </div>
        <div className='content'>
          <p>HI , I'M A Web Developer</p>
          <h1 class="typing">Data Scientist</h1>

          <div>
            <Link to="/skills">
            <button id="button2"> Skills</button>
            </Link>
            <Link to="/contact">
            <button id="button1"> Contact</button>
            </Link>
          </div>
        </div>
    </div>
  )
}

export default Heroimage