import React from 'react'
import Footer from './components/Footer'
import Heroimage2 from './components/Heroimage2'
import Navbar from './components/Navbar'
import SkillsBar from './components/SkillsBar'

const Skills = () => {
    return (
        <div>
            <Navbar />
            <Heroimage2 heading="SKILLS." text="I am Front-End Developer." />
            <SkillsBar/>
            <Footer />
        </div>
    )
}

export default Skills
