import React, { useState } from 'react'
import Heroimage from './components/Heroimage'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Form from './components/Form'
import SkillsBar from './components/SkillsBar'


const Home = () => {
    const [toggle,setToggle] = useState(false);
    const handleClick = () =>{
      setToggle(!toggle);
      console.log(toggle);
    }
    return (
        <div>
            <Navbar toggleClick={handleClick} toggle={toggle}/>
            <Heroimage/>
            <SkillsBar/>
            <Form/>
            <Footer />
        </div>
    )
}

export default Home
