import React from "react";
import { Routes,Route } from "react-router-dom";
import Project from "./project";
import Contact from "./Contact";
import Home from "./Home";

import "./index.css"
import Skills from "./Skills";
import Toggle from "./components/Toggle";
function App() {

  return (
    <>
    <Routes>
      <Route path="/"element={<Home/>}/>
      <Route path="/project"element={<Project/>}/>
      <Route path="/contact"element={<Contact/>}/>
      <Route path="/skills"element={<Skills/>}/>
      <Route path="/test" element={<Toggle/>}/>
    </Routes> 
    </>
  );
}

export default App;